<template>
  <div
    v-if="props.transcriptionTypeId && $store.getters['requests/getTranscriptionType'](props.transcriptionTypeId)"
    class="tw-flex tw-items-center tw-h-full"
  >
    <el-tooltip
      :content="$store.getters['requests/getTranscriptionType'](props.transcriptionTypeId).nameTooltip"
      placement="right"
      :disabled="props.client?.lang !== 'ua'"
    >
      <div class="tw-flex tw-items-center tw-h-full">
        <component
          :is="$store.getters['requests/getTranscriptionType'](props.transcriptionTypeId).icon"
          v-if="!props.hideIcon"
          :class="`color-${$store.getters['requests/getTranscriptionType'](props.transcriptionTypeId).color}`"
          class="icon tw-mr-2"
        />
        <div
          class="tw-font-semibold"
        >
          {{ $store.getters['requests/getTranscriptionType'](props.transcriptionTypeId).name }}
        </div>
      </div>
    </el-tooltip>
  </div>
</template>

<script setup>
const props = defineProps({
  transcriptionTypeId: {
    type: Number,
    default: null,
  },
  client: {
    type: Object,
    default: null,
  },
  hideIcon: {
    type: Boolean,
    default: false,
  },
});
</script>
